import { GameName, GamePathByName } from "../enums/game-name.enum";

type Params = {
  name: GameName
}

export const GameIframe = ({ name }: Params) => {
  return (
    <div className="fixed inset-0 w-full h-screen bg-black">
      <iframe
          src={`./games/${GamePathByName[name]}/index.html`}
          title={name}
          className="w-full h-full border-0"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            margin: 0,
            padding: 0,
            overflow: 'hidden'
          }}
          sandbox="allow-scripts allow-same-origin"
        />
      </div>
  );
};