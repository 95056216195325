import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home";
import { GameIframe } from "./components/game-iframe";
import { GameName, GameRoutePath } from "./enums/game-name.enum";

const App = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path={GameRoutePath.Blackjack} element={<GameIframe name={GameName.Blackjack} />} />
        <Route path={GameRoutePath.CutAndSlice} element={<GameIframe name={GameName.CutAndSlice} />} />
        <Route path={GameRoutePath.FeedTheSnake} element={<GameIframe name={GameName.FeedTheSnake} />} />
        <Route path={GameRoutePath.GolfMagic} element={<GameIframe name={GameName.GolfMagic} />} />
        <Route path={GameRoutePath.KnivesOut} element={<GameIframe name={GameName.KnivesOut} />} />
        <Route path={GameRoutePath.MonsterMatch} element={<GameIframe name={GameName.MonsterMatch} />} />
        <Route path={GameRoutePath.Solitaire} element={<GameIframe name={GameName.Solitaire} />} />
        <Route path={GameRoutePath.SpaceEscape} element={<GameIframe name={GameName.SpaceEscape} />} />
        <Route path={GameRoutePath.Tetris} element={<GameIframe name={GameName.Tetris} />} />
        <Route path={GameRoutePath.WatchTheCup} element={<GameIframe name={GameName.WatchTheCup} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
