import BlazefunLogo from '../assets/BlazefunLogo.svg';
import BlazesoftLogo from '../assets/BlazesoftLogo.svg';
import LinkedIn from "../assets/icons/LinkedIn.svg"
import Blaze from "../assets/icons/Blaze.svg"
import GreatPlaceToWorkCertificate from "../assets/icons/GreatPlaceToWorkCertificate.svg"
import { useNavigate } from 'react-router-dom';
import { GameName, GameRoutePathByName } from '../enums/game-name.enum';


export const Footer = () => {
  const navigate = useNavigate();

  const openGame = (name: GameName) => {
    navigate(GameRoutePathByName[name]);
  };

  return (
    <footer className="bg-blazefun-footer w-full xs:h-[35rem] lg:h-[27rem] relative overflow-hidden">
      <img
        className='absolute right-0 bottom-0 lg:translate-y-0 lg:translate-x-0 xs:-translate-y-[15%] xs:w-[27.5rem] xs:h-[27.5rem] lg:w-[17rem] lg:h-[24.5rem]'
        src={Blaze} 
        alt=""
      />
    
      <div className='desktop-container xs:hidden lg:flex'>
        <div className='left-container flex flex-row basis-2/3 w-full h-full p-24'>
          
          <div className='branding-container flex flex-col w-full'>
            <div className='links-container w-full'>
              <img src={BlazefunLogo} alt="Blazefun logo" className='flex-1 w-32 xs:w-40 h-6 xs:h-8'/>
              <img src={LinkedIn} alt="LinkedIn icon" className='flex-2 w-8 h-8 mt-4 xs:mt-5'/>
            </div>

            <div className='powered-by-container mt-10'>
              <p className="font-['Baloo_2'] text-lg xs:text-xl text-white">Powered By</p>
              <img src={BlazesoftLogo} alt="Blazesoft logo" className='flex-1 w-36 mt-2 xs:w-40 h-7 xs:h-8'/>
            
              <div className='copyright-container'>
                <p className="font-['Baloo_2'] text-sm text-white mt-4">Copyright © Blazesoft ltd - 2023. All Rights Reserved</p>
              </div>
            </div>
          </div>
          <div className='explore-container flex flex-col w-full h-full flex-1'>
            <h2 className="font-['Baloo_2'] text-2xl text-white mb-8">Explore Our Games:</h2>
            
            <div className="games-grid grid grid-cols-[repeat(2,_theme(width.36))] gap-2">
              <div className="w-36 text-white text-xl font-['Baloo_2'] cursor-pointer" onClick={() => openGame(GameName.Tetris)}>Tetris</div>
              <div className="w-36 text-white text-xl font-['Baloo_2'] cursor-pointer" onClick={() => openGame(GameName.KnivesOut)}>Knives out</div>
              <div className="w-36 text-white text-xl font-['Baloo_2'] cursor-pointer" onClick={() => openGame(GameName.Blackjack)}>Blackjack</div>
              <div className="w-36 text-white text-xl font-['Baloo_2'] cursor-pointer" onClick={() => openGame(GameName.MonsterMatch)} >Monster match</div>
              <div className="w-36 text-white text-xl font-['Baloo_2'] cursor-pointer" onClick={() => openGame(GameName.Solitaire)}>Solitaire</div>
              <div className="w-36 text-white text-xl font-['Baloo_2'] cursor-pointer" onClick={() => openGame(GameName.GolfMagic)}>Golf magic</div>
              <div className="w-36 text-white text-xl font-['Baloo_2'] cursor-pointer" onClick={() => openGame(GameName.CutAndSlice)}>Cut and slice</div>
              <div className="w-36 text-white text-xl font-['Baloo_2'] cursor-pointer" onClick={() => openGame(GameName.SpaceEscape)}>Space escape</div>
              <div className="w-36 text-white text-xl font-['Baloo_2'] cursor-pointer" onClick={() => openGame(GameName.FeedTheSnake)}>Feed the snake</div>
              <div className="w-36 text-white text-xl font-['Baloo_2'] cursor-pointer" onClick={() => openGame(GameName.WatchTheCup)}>Watch the cup</div>
            </div>
          </div>
        </div>
      </div>

      <div className='mobile-container xs:flex lg:hidden flex-col p-6'>
        <div className='explore-container flex flex-col'>
          <h2 className="font-['Baloo_2'] text-2xl text-white mb-5">Explore Our Games:</h2>
          
          <div className="games-grid grid grid-cols-[repeat(2,_theme(width.36))] gap-2">
            <div className="w-36 text-white text-xl font-['Baloo_2']">Tetris</div>
            <div className="w-36 text-white text-xl font-['Baloo_2']">Knives out</div>
            <div className="w-36 text-white text-xl font-['Baloo_2']">Blackjack</div>
            <div className="w-36 text-white text-xl font-['Baloo_2']">Monster match</div>
            <div className="w-36 text-white text-xl font-['Baloo_2']">Solitaire</div>
            <div className="w-36 text-white text-xl font-['Baloo_2']">Golf magic</div>
            <div className="w-36 text-white text-xl font-['Baloo_2']">Cut and slice</div>
            <div className="w-36 text-white text-xl font-['Baloo_2']">Space escape</div>
            <div className="w-36 text-white text-xl font-['Baloo_2']">Feed the snake</div>
            <div className="w-36 text-white text-xl font-['Baloo_2']">Watch the cup</div>
          </div>
        </div>
        
        <div className='flex flex-row mt-10'>
          <div className='branding-container flex flex-row'>
            <div className='links-container'>
              <img src={BlazefunLogo} alt="Blazefun logo" className='flex-1 w-40 h-8'/>
              <img src={LinkedIn} alt="LinkedIn icon" className='flex-2 w-8 h-8 lg:mt-5 xs:mt-4'/>

              <div className='powered-by-container mt-6'>
                <p className="font-['Baloo_2'] text-lg xs:text-xl text-white">Powered By</p>
                <img src={BlazesoftLogo} alt="Blazesoft logo" className='flex-1 w-36 mt-2 xs:w-40 h-7 xs:h-8'/>
              
                <div className='copyright-container'>
                  <p className="font-['Baloo_2'] text-sm text-white mt-4">Copyright © Blazesoft ltd - 2023. All Rights Reserved</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


    </footer>
  );
};