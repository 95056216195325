import BlazefunLogo from '../assets/BlazefunLogo.svg';

export const HeroSection = () => {
  return (
    <section className='bg-blazefun-orange'>
      <div className='container flex flex-col items-center justify-center lg:w-[54.25rem] xs:w-[21.6875rem] lg:h-[25rem] xs:h-[26rem] lg:py-24 xs:py-10'>
        <div className='about-container'>
          <p className="font-['Baloo_2'] text-center text-blazefun-navy text-lg xs:text-xl lg:px-4 xs:px-6">
            Blazefun, a proud part of Blazesoft, delivers top-tier social games designed for non-stop, free daily entertainment. Experience the thrill of our in-house creations, crafted to bring endless fun straight to you!
          </p>
        </div>
      </div>
    </section>
  );
};