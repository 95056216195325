import { GamesSection } from "../components/games-section";
import MainLayout from "../layouts/main.layout";

export const Home = (): JSX.Element => {
  return (
    <MainLayout>
      <GamesSection />
    </MainLayout>
  );
}
