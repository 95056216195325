import { Footer } from "../components/footer";
import { Header } from "../components/header";
import { HeroSection } from "../components/hero-section";

const MainLayout = ({ children }: any) => {
  return (
    <div className="flex flex-col max-w-screen min-h-screen bg-blazefun-navy">
      <Header />
      <HeroSection />
      <main className="flex-1">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;
